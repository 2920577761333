/*.App {*/
/*text-align: center;*/
/*}*/

/*.App-logo {*/
/*animation: App-logo-spin infinite 20s linear;*/
/*height: 40vmin;*/
/*}*/

/*.App-link {*/
/*color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*from {*/
/*transform: rotate(0deg);*/
/*}*/
/*to {*/
/*transform: rotate(360deg);*/
/*}*/
/*}*/

/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  color: white;
  /*font-family: 'Montserrat', sans-serif;*/
  font-family: "Josefin Sans", sans-serif;
}

.bg-image-custom {
  background-position: center;
  padding-top: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  height: 400px;
}

.social-icon {
  margin-left: 2px;
  margin-right: 2px;
}

.navbar {
  /*border-bottom: 1px solid rgba(0,0,0,0.05);*/
  margin-bottom: 10px;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 10px 10px;
}

.badge {
  padding-top: 4px;
}

.footer-em {
  /*position: absolute;*/
  left: 0;
  bottom: 0;
  right: 0;
}

.email-list-box {
  font-family: "Montserrat", sans-serif;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  padding: 12px;
  font-size: 12px;
}

.hover-btn {
  border: 1px solid #e7e7e7;
}

.rounded-more {
  border-radius: 12px;
}

.hover-btn:hover {
  background-color: #fefefe;
  color: #272727;
  border: 1px solid #696969;
  /*font-weight: bold;*/
}

.line-h-1 {
  line-height: 1;
}